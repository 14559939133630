import React from 'react';
import styled from 'styled-components';

import CenterWrapper from "./CenterWrapper";
import Button from "./Button";

import { ReactComponent as Logo } from '../assets/logo-navbar.svg';

const NavbarWrapper = styled.header`
    position: sticky;
    top: 0; left: 0;
    width: 100%;
    background: #fff;
    z-index: 10;
`;

const NavbarCenterWrapper = styled(CenterWrapper)`
    padding: 18px;

    background: #fff;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
        width: 154px; height: 43px;
        object-fit: contain;
        &:hover {
            cursor: pointer;
        }
    }

    aside {
        display: flex;
        align-items: center;
    }
`;

const Menu = styled.ul`
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
`;

const MenuItem = styled.li`
    &:not(:last-child) {
        margin-right: 26px;
    }

    &:last-child {
        margin-right: 40px;
    }
`;


const Navbar: React.FC = () => {
    return (
        <NavbarWrapper>
            <NavbarCenterWrapper>
                <Logo className="logo" onClick={() => window.scrollTo(0, 0)}/>
                <aside>
                    <Menu>
                        <MenuItem>Main</MenuItem>
                        <MenuItem>왜 ACHER 인가요?</MenuItem>
                        <MenuItem>편리한 인증</MenuItem>
                        <MenuItem>암호화폐 지갑</MenuItem>
                        <MenuItem>PC/모바일 대응</MenuItem>
                        <MenuItem>다운로드</MenuItem>
                    </Menu>
                    <Button>문의하기</Button>
                </aside>
            </NavbarCenterWrapper>
        </NavbarWrapper>
    );
};

export default Navbar;

import React from 'react';
import styled from 'styled-components';

import { NormalText } from "../Text";

type ReactSVGElement = React.FC<React.SVGProps<SVGSVGElement>>;

const FlexCheckBox = styled.div<ReversibleProps>`
    display: flex;
    flex-direction: ${({reversed}) => reversed ? 'row-reverse' : 'row'};
    align-items: center;
    margin-bottom: 50px;
`;

const DescBox = styled.div<ReversibleProps>`
    text-align: ${({reversed}) => reversed ? "left" : "right"};
    margin-right: 15px;

    &>p {
        font-size: 24px;
        color: #020527;
    }

    & p {
        margin: 2px;
    }
`;

type CheckDescProps = {
    Icon: ReactSVGElement;
    Title: string;
    Description: JSX.Element;
    reversed?: boolean;
}

type ReversibleProps = {
    reversed?: boolean;
};


const CheckDesc: React.FC<CheckDescProps> = ({Icon, Title, Description, reversed}) => {
    return (
        <FlexCheckBox reversed={reversed}>
            <DescBox reversed={reversed}>
                <p>{Title}</p>
                <NormalText color="#44494d" fontSize="12px">
                    <p>{Description}</p>
                </NormalText>
            </DescBox>
            <Icon />
        </FlexCheckBox>
    )
}

export default CheckDesc;

import React from 'react';
import styled from 'styled-components';

import { NormalText } from "../Text";

type ReactSVGElement = React.FC<React.SVGProps<SVGSVGElement>>;

const FlexCheckBox = styled.div<MainSlideBtn>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${({isMain}) => isMain ? '0px' : '200px'};
    width: ${({isMain}) => isMain ? '160px' : '250px'};
    height: ${({isMain}) => isMain ? '50px' : '80px'};
    background-color: ${({backgroundColor}) => backgroundColor || '#fff'};
    border-radius: ${({isMain}) => isMain ? '4px' : '10px'};
    padding-left:18px;

    margin-right: ${({isMain}) => isMain ? '25px' : '0px'};

    transition: box-shadow 0.3s;

    &:hover {
        box-shadow: ${({isMain}) => isMain ? 'none' : '0 10px 20px 0 rgba(1, 2, 28, 0.16)'};
    }
`;

const DescBox = styled.div<MainSlideBtn>`
    margin-right: 15px;

    &>p {
        font-size: 16px;
        font-size: ${({isMain}) => isMain ? '14px' : '16px'};
        color: ${({isMain}) => isMain ? '#fff' : '#020527'};
        padding-left: 16px;
        line-height: ${({isMain}) => isMain ? '0.75' : '1.25'};
    }
`;

type AppDownloadBtnProps = {
    Icon: ReactSVGElement;
    Description?: JSX.Element;
    width?: string;
    height?: string;
    isMain?: boolean;
    backgroundColor?: string;
}

type MainSlideBtn = {
    isMain?: boolean;
    backgroundColor?: string;
}


const AppDownloadBtn: React.FC<AppDownloadBtnProps> = ({Icon, Description, isMain, backgroundColor}) => {
    return (
        <FlexCheckBox isMain={isMain} backgroundColor={backgroundColor}>
            <Icon />
            <DescBox isMain={isMain}>
                <p>{Description}</p>
            </DescBox>
        </FlexCheckBox>
    )
}

export default AppDownloadBtn;

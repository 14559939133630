import React from 'react';
import './App.scss';

import Navbar from "./components/Navbar";

import Page1 from "./pages/Page1";
import Page2 from "./pages/Page2";
import Page3 from "./pages/Page3";
import Page4 from "./pages/Page4";

function App() {
    return (
        <>
            <Navbar />
            <Page1 />
            <Page2 />
            <Page3 />
            <Page4 />
        </>
  );
}

export default App;

import React from 'react';
import styled from 'styled-components';

import { NormalText } from '../Text';

import StepHover      from '../../assets/Page2/step-hover.svg';
import StepHoverWhite from '../../assets/Page2/step-hover-white.svg';

type ReactSVGElement = React.FC<React.SVGProps<SVGSVGElement>>;

const AcherStartContainer = styled.section`
    margin-bottom: 75px;

    cursor: pointer;

    &:last-child {
        padding-bottom: 90px;
        margin-bottom: 0px;
    }
`;

const DescriptionBox = styled.p<{isTitle?: boolean, selected?: boolean, textColor?: string}>`
    margin-top: 0px;
    font-size: ${props => (props.isTitle ? '28px' : '14px')};
    margin-bottom: ${props => (props.isTitle ? '10px' : '0px')};
    color: ${({textColor}) => textColor || '#000' };

    ${props => props.isTitle && props.selected && `
        font-weight: 900;
    `}
`;

const StepDescription = styled.p<{isDescripion?: boolean, textColor?: string}>`
    margin: 14px 0 0;

    &:last-child {
        margin-top: 0;
    }

    font-size: ${props => (props.isDescripion ? '10px' : '16px')};
    color: ${({textColor}) => textColor || "#000"};
`;

const StepBox = styled.div<BaseStyleProps & { isWhite?: boolean }>`
    position: relative;
    width: 130px;
    height: 130px;
    border-radius: 12px;

    background: ${({backgroundColor}) => backgroundColor || "#fff"};

    padding: 20px 0 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    margin-right:50px;
    cursor: default;

    overflow: hidden;
    transition: box-shadow 0.3s;


    & > div {
        z-index: 1;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0; top: 0;
        width: 100%; height: 100%;
        background: url(${(props) => props.isWhite ? StepHoverWhite : StepHover}) no-repeat;
        background-size: contain;

        opacity: 0;

        transition: opacity 0.5s;
    }

    /* selected가 true일 때 해당됨 */
    ${props => props.selected && `
        box-shadow: 0 10px 20px 0 rgba(1, 2, 28, 0.16);
        cursor: pointer;

        &:before { opacity: 1; }

        svg, path {
            fill: ${props.isWhite ? '#000' : '#fff'} !important;
        }

        p {
            color: ${props.isWhite ? '#000' : '#fff'};

            &:last-child {
                color: ${props.isWhite ? "#000" : "#84acff"};
            }
        }
    `}
`;

const FlexBox = styled.div`
    display: flex;
    flex-direction: row;
`;

const CenterBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

type AcherStartProps = BaseStyleProps & {
    Icon: ReactSVGElement;
    IconTitle: string;
    descriptionTitle: string;
    description: JSX.Element;
    stepNumber: number;
}

type BaseStyleProps = {
    selected?: boolean;
    backgroundColor?: string;
    textColor?: string;
}

const AcherStart: React.FC<AcherStartProps> = ({Icon, IconTitle, stepNumber, descriptionTitle, description, selected, backgroundColor, textColor}) => {
    return (
        <AcherStartContainer className="disable-selection">
            <FlexBox>
                <StepBox selected={selected} backgroundColor={backgroundColor} isWhite={textColor === '#fff'}>
                    <div>
                        <Icon />
                        <StepDescription textColor={textColor}>{IconTitle}</StepDescription>
                        <StepDescription isDescripion={true} textColor={textColor}>Step{stepNumber}</StepDescription>
                    </div>
                </StepBox>

                <CenterBox>
                    <DescriptionBox isTitle={true} selected={selected} textColor={textColor}>{descriptionTitle}</DescriptionBox>
                    <NormalText>
                        <DescriptionBox textColor={textColor}>{description}</DescriptionBox>
                    </NormalText>
                </CenterBox>
            </FlexBox>
        </AcherStartContainer>
    )
}

export default AcherStart;

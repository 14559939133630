import React from 'react';
import styled from 'styled-components';

import CenterWrapper from "./CenterWrapper";

const DEFAULT_BASE_WIDTH = '1000px';
const DEFAULT_HEIGHT     = 'auto';

type BasePageProps = {
    baseWidth?: string;
    // HACK: height로 네이밍하면 웹 브라우저 상에 뜸
    elementHeight?: string;

    backgroundColor?: string;
};

/**
 * @name BasePageWrapper
 * @description
 *
 * 모바일에서는 특성상 height가 걸리지 않습니다.
 */
const BasePageWrapper = styled.section<BasePageProps>`
    width: 100%;
    height: ${({elementHeight}) => elementHeight || DEFAULT_HEIGHT};

    box-sizing: border-box;
    padding: 1em;

    background-color: ${({backgroundColor}) => backgroundColor || 'transparent'};

    @media screen and (max-width: ${({baseWidth}) => baseWidth || DEFAULT_BASE_WIDTH}) {
        height: auto;
        padding: 0;
    }
`;

const BasePage: React.FC<BasePageProps> = (props) => {
    return (
        <BasePageWrapper {...props}>
            <CenterWrapper {...props}>
                {props.children}
            </CenterWrapper>
        </BasePageWrapper>
    );
};


export default BasePage;

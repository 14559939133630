import React from "react";
import styled from "styled-components";

import BasePage from "../components/BasePage";
import CheckDesc from "../components/Page4/CheckDesc";
import AppDownloadBtn from "../components/Page4/AppDownloadBtn";
import FooterLogo from "../components/Page4/FooterLogo";

import { NormalText } from "../components/Text";

import { ReactComponent as NewAuth } from "../assets/Page4/new-auth.svg";
import { ReactComponent as CheckIco } from "../assets/Page4/check-ico.svg";
import { ReactComponent as Galaxy } from "../assets/Page4/galaxy-ico.svg";
import { ReactComponent as Apple } from "../assets/Page4/apple-ico.svg";
import { ReactComponent as Chrome } from "../assets/Page4/chrome-ico.svg";

import AcherLogo from "../assets/Page4/acher-logo.svg";
import ScvSoft from "../assets/Page4/scvsoft-logo.svg";

import Facebook from "../assets/Page4/facebook-logo.svg";
import Medium from "../assets/Page4/medium-logo.svg";
import Twitter from "../assets/Page4/twitter-logo.svg";

import PhoneWeb from "../assets/Page4/phone-web.png";

const PaddingBox = styled.div`
    padding-top: 120px;
`;

const Container = styled.div<{isFooter?: boolean}>`
    display: flex;
    flex-direction: row;
    justify-content: ${props => (props.isFooter ? "left" : "center")};

    &>div {
        margin-right: ${props => (props.isFooter ? "140px" : "30px")};

        &:last-child {
           margin-right: 0;
        }
    }
`;

const FooterContainer = styled(Container)`
    justify-content: space-between;
    margin-top: 60px;
    margin-bottom: 100px;
`;

const FooterIcon = styled.div`
    display: flex;
    flex-direction: row;

    &>img {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
`;

const FooterMenuList = styled.ul`
    padding: 0px;

    &>li {
        list-style: none;
        font-size: 12px;
        color: #43455c;
        margin-bottom: 14px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
`

const Page4: React.FC = () => {
    return (
        <BasePage backgroundColor='#f0f1f2'>
            <PaddingBox style={{ display: 'flex' }}>
                <div>
                    <CheckDesc Icon={CheckIco}
                               Title="본인확인 역시 쉽습니다."
                               Description={<>ACHER는 로그인, 본인인증 신원 확인 등<br />모든 서비스에서 작동됩니다.</>} />

                    <CheckDesc Icon={CheckIco}
                               Title="본인확인 역시 쉽습니다."
                               Description={<>ACHER는 로그인, 본인인증 신원 확인 등<br />모든 서비스에서 작동됩니다.</>} />

                    <CheckDesc Icon={CheckIco}
                               Title="본인확인 역시 쉽습니다."
                               Description={<>ACHER는 로그인, 본인인증 신원 확인 등<br />모든 서비스에서 작동됩니다.</>} />
                </div>

                <img src={PhoneWeb} alt="앞에는 휴대폰 화면에는 아처 화면이 띄어져있고,
                                        뒤에는 큰 화면에 인터넷 웹이 띄어져 있고,
                                        확장프로그램으로 아처가 띄어져 있다." />

                <div>
                    <CheckDesc Icon={CheckIco}
                               Title="본인확인 역시 쉽습니다."
                               Description={<>ACHER는 로그인, 본인인증 신원 확인 등<br />모든 서비스에서 작동됩니다.</>}
                               reversed />

                    <CheckDesc Icon={CheckIco}
                               Title="본인확인 역시 쉽습니다."
                               Description={<>ACHER는 로그인, 본인인증 신원 확인 등<br />모든 서비스에서 작동됩니다.</>}
                               reversed />

                    <CheckDesc Icon={CheckIco}
                               Title="본인확인 역시 쉽습니다."
                               Description={<>ACHER는 로그인, 본인인증 신원 확인 등<br />모든 서비스에서 작동됩니다.</>}
                               reversed />
                </div>
            </PaddingBox>

            <div className="centered-text new-auth-text">
                <NewAuth title="스마트폰과 Chrome에서 만나보는 새로운 신원인증" aria-labelledby="title" />
                <NormalText color="#44494d" margin="20px">
                    ACHER는 로그인, 본인인증 신원 확인 등 모든 서비스에서 작동됩니다.<br />원래 하던 그대로, 확인 버튼만 누르면 됩니다.
                </NormalText>
            </div>

            <Container>
                <AppDownloadBtn Icon={Galaxy}
                                Description={<>갤럭시 및 안드로이드<br />다운로드하기</>} />

                <AppDownloadBtn Icon={Apple}
                                Description={<>아이폰/아이패드용<br />다운로드하기</>} />

                <AppDownloadBtn Icon={Chrome}
                                Description={<>Google Chrome용<br />다운로드하기</>} />
            </Container>

            <Container isFooter={true}>
                <FooterLogo Icon={AcherLogo}
                            Description={
                                <>ACHER는 블록체인 기반의 탈중앙화 모바일<br />
                                앱(Dapp) 으로 사용자가 개개인의 신원 인증<br />
                                정보를 본인의 IT기기에서 직접 관리하고 확<br/>
                                인할 수 있는 서비스 입니다.</>
                            } />

                <FooterLogo Icon={ScvSoft}
                            Description={
                                <>SCVSoft는 블록체인 기반 신원인증 앱<br />
                                ACHER의 개발사로, 사업자에 맞춘 블록체인<br />
                                기반의 암호화폐 개발 및 보안 솔루션/각종<br/>
                                분야 유지보수 사업을 진행하고 있습니다.</>
                            } />

                <div>
                    <p>ACHER</p>
                    <FooterMenuList>
                        <li>왜 아처인가요?</li>
                        <li>새로운 인증의 시대</li>
                        <li>아처는 간편합니다</li>
                        <li>다운로드하기</li>
                    </FooterMenuList>
                </div>

                <div>
                    <p>SCVSoft</p>
                    <FooterMenuList>
                        <li>당신의 사업 파트너</li>
                        <li>개별 역량 소개</li>
                        <li>대표 서비스 소개</li>
                        <li>프로젝트 진행 방식 안내</li>
                    </FooterMenuList>
                </div>
            </Container>

            <FooterContainer>
                <FooterIcon>
                    <img src={Facebook} alt="facebook" />
                    <img src={Medium} alt="medium" />
                    <img src={Twitter} alt="twitter" />
                </FooterIcon>

                <NormalText color="#43455c" fontSize="12px">
                    Copyright 2020. SCVSoft. All rights reserved.
                </NormalText>
            </FooterContainer>
        </BasePage>
    );
};

export default Page4;

import React, {useEffect, useState} from "react";
import styled from "styled-components";

import BasePage from "../components/BasePage";
import { NormalText } from "../components/Text";
import AppDownloadBtn from "../components/Page4/AppDownloadBtn";

import { ReactComponent as HeaderText } from "../assets/Page1/header-text.svg";
import SliderPrev from "../assets/Page1/main-arrow-left.svg";
import SliderNext from "../assets/Page1/main-arrow-right.svg";
import { ReactComponent as PlayStroe } from "../assets/Page1/playstore-ico.svg";
import { ReactComponent as AppStore } from "../assets/Page1/appstore-ico.svg";

import Phone1Img from '../assets/Page1/phone1.png';
import Colors from "../Colors";

import Swiper, {SwiperInstance} from 'react-id-swiper';

import 'swiper/css/swiper.css'

const Page1Wrapper = styled(BasePage)`
    position: relative;

    display: flex;
    padding-bottom: 0;

    & > * {
        flex: 1;
    }

    summary {
        display: flex;
        justify-content: center;
        flex-direction: column;

        ${NormalText} {
            margin-top: 24px;
        }
    }

    aside {
        display: flex;

        .swiper-container {
            width: 100%;
            max-width: 550px;
        }
        .swiper-wrapper {
            img {
                width: 550px;
                object-fit: contain;
                object-position: bottom;
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            display: block;
        }

        .swiper-pagination-bullet {
            width: 20px;
            height: 4px;
            border-radius: 0;
        }

        .swiper-pagination-bullet-active {
            background-color: #000;
        }
    }

    footer.pagination {
        position: absolute;
        bottom: 34px; left: 0;
        display: flex;

        div {
            width: 20px; height: 4px;
            margin-right: 6px;

            background: #e3e3e3;
            transition: background 0.3s;

            &.active {
                background: ${Colors.TEXT_MIDNIGHT};
            }
        }
    }
`;

const MainSlideBtnBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 40px;
`;

const SlideButton = styled.button`
    width: 32px;
    height: 32px;
    border: none;
    background: none;
    top: 60%;

    &:after {
        content: none;
    }
`;

const MainBtnFontSize = styled.p`
    margin: 0;
    font-size: 10px;
`;

const Page1: React.FC = () => {
    const [swiper, setSwiper] = useState<SwiperInstance>(null);
    const [slides, setSlides] = useState<number>(0);
    const [currentSlide, setCurrentSlide] = useState<number>(0);

    const slideParams = {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        renderNextButton: () => <SlideButton className="swiper-button-next"><img src={SliderNext} alt="다음" /></SlideButton>,
        renderPrevButton: () => <SlideButton className="swiper-button-prev"><img src={SliderPrev} alt="이전" /></SlideButton>,
        spaceBetween: 30,
        loop: {
            loopedSlides: true,
        },
    };

    useEffect(() => {
        if (swiper !== null) {
            // FIXME
            setSlides(3);
            setCurrentSlide(swiper.realIndex);
            swiper.on('slideChange', (event: any) => {
                setCurrentSlide(swiper.realIndex);
            });
        }
    }, [swiper]);

    return (
        <Page1Wrapper elementHeight="840px">
            <summary>
                {/* 상용 폰트를 사용할 수 없어 SVG로 대체. */}
                <HeaderText title="블록체인 위의 신원인증" aria-labelledby="title" />
                <NormalText color={Colors.TEXT_SLATE}>
                    ACHER는 블록체인 기반의 탈중앙화 모바일 앱(Dapp)으로<br/>
                    사용자가 개개인의 신원 인증정보를 본인의 IT기기에서<br/>
                    직접 관리하고 확인할 수 있는 서비스입니다.
                </NormalText>

                <MainSlideBtnBox>
                    <AppDownloadBtn Icon={AppStore}
                                    Description={<><MainBtnFontSize>아이폰용 앱</MainBtnFontSize><br />다운로드 하기</>}
                                    backgroundColor="#20b4f4"
                                    isMain />
                    <AppDownloadBtn Icon={PlayStroe}
                                    Description={<><MainBtnFontSize>갤럭시/안드로이용 앱</MainBtnFontSize><br />다운로드 하기</>}
                                    backgroundColor="#145ef8"
                                    isMain />
                </MainSlideBtnBox>
            </summary>
            <aside>
                <Swiper {...slideParams} getSwiper={setSwiper}>
                        <img src={Phone1Img}
                             alt="Acher가 실행되어 있는 휴대폰 화면. Acher 로고와 서명을 하는 사람의 일러스트, 그리고 로그인 버튼과 회원가입 버튼이 배치되어 있다."/>
                        <img src={Phone1Img}
                             alt="Acher가 실행되어 있는 휴대폰 화면. Acher 로고와 서명을 하는 사람의 일러스트, 그리고 로그인 버튼과 회원가입 버튼이 배치되어 있다."/>
                        <img src={Phone1Img}
                             alt="Acher가 실행되어 있는 휴대폰 화면. Acher 로고와 서명을 하는 사람의 일러스트, 그리고 로그인 버튼과 회원가입 버튼이 배치되어 있다."/>
                </Swiper>
            </aside>
            <footer className="pagination">
                {
                    Array.from(new Array(slides)).map((x, index) =>
                        <div className={index === currentSlide ? "active" : ""} />
                    )
                }
            </footer>
        </Page1Wrapper>
    );
};

export default Page1;

import styled from 'styled-components';

type NormalTextProps = {
    color?: string;
    fontSize?: string;
    margin?: string;
}

export const NormalText = styled.div<NormalTextProps>`
    font-size: ${({fontSize}) => fontSize || '16px'};
    line-height: 1.5;

    color: ${({color}) => color || '#000'};

    margin-top: ${({margin}) => margin || '0px'};
`;
export const Header1 = styled.h1<NormalTextProps>`
    margin: 0; padding: 0;
    font-size: 28px;
    font-weight: 900;
`;
export const Header2 = styled.h2<NormalTextProps>`
    margin: 0; padding: 0;
    font-size: 24px;
    font-weight: 900;
`;

export const Header3 = styled.h3<NormalTextProps>`
    margin: 0; padding: 0;
    font-size: 22px;
    font-weight: 900;
`;

import React, {useState} from "react";
import styled from "styled-components";

import BasePage from "../components/BasePage";
import WhyBox from "../components/Page2/WhyBox";

import AcherStart from "../components/Page2/AcherStart";

import { ReactComponent as EasyIcon } from "../assets/Page2/icon-easy.svg";
import { ReactComponent as LampIcon } from "../assets/Page2/icon-lamp-lightning.svg";
import { ReactComponent as ThunderIcon } from "../assets/Page2/icon-thunder.svg";

import { ReactComponent as UserIcon } from "../assets/Page2/user-icon.svg";
import { ReactComponent as RockIcon } from "../assets/Page2/rock-icon.svg";
import { ReactComponent as CheckIcon } from "../assets/Page2/check-icon.svg";

import { ReactComponent as WhyAcher } from "../assets/Page2/why-acher.svg";
import { ReactComponent as NewAcher } from "../assets/Page2/new-acher.svg";

import PhoneImg from "../assets/Page2/phone-img.png";

const Page2Wrapper = styled(BasePage)`
    input[type=radio] {
        display: none;
    }
`

const Page2: React.FC = () => {
    const [selectedShowcase, setSelectedShowcase] = useState<number>(1);
    return (
        <Page2Wrapper backgroundColor='#f0f1f2'>
            <div className="centered-text why-acher-text">
                <WhyAcher title="왜 아처인가요?" aria-labelledby="title" />
            </div>
            <div style={{ display: 'flex' }}>
                <WhyBox Icon={EasyIcon}
                        title="편리합니다"
                        description="ACHER는 어떠한 인증 서비스보다도 편하고 쉽게 인증이 가능합니다. 번거로운 휴대폰 인증문자 과정을 거치지 않습니다" />
                <WhyBox Icon={LampIcon}
                        title="편리합니다"
                        description="ACHER는 어떠한 인증 서비스보다도 편하고 쉽게 인증이 가능합니다. 번거로운 휴대폰 인증문자 과정을 거치지 않습니다" />
                <WhyBox Icon={ThunderIcon}
                        title="편리합니다"
                        description="ACHER는 어떠한 인증 서비스보다도 편하고 쉽게 인증이 가능합니다. 번거로운 휴대폰 인증문자 과정을 거치지 않습니다" />
            </div>

            <div className="centered-text new-acher-text">
                <NewAcher title="새로운 인증의 시대, 아처로부터 시작." aria-labelledby="title" />
            </div>
            <div className="acher-start-box">
                <img src={PhoneImg}
                     alt="인증 요청을 위해 Acher가 실행되어 있는 휴대폰 화면.
                          화면에는 인증을 요청한 서비스 이름과, 인증 목적, 인증 요청 시각이 적혀 있고,
                          비밀번호 입력을 위한 키패드와 인증 거부를 위한 '내 요청 아님' 버튼이 배치되어 있다." />
                <div>
                    <div onMouseEnter={() => setSelectedShowcase(1)}>
                        <AcherStart Icon={UserIcon}
                                    IconTitle="본인 등록"
                                    descriptionTitle="간단하게 본인을 등록하세요."
                                    description={<>ACHER는 로그인, 본인인증 신원 확인 등<br />모든 서비스에서 작동됩니다. 원래 하던 그대로,<br />확인 버튼만 누르면 됩니다.</>}
                                    stepNumber={1}
                                    selected={selectedShowcase === 1}/>
                    </div>
                    <div onMouseEnter={() => setSelectedShowcase(2)}>
                        <AcherStart Icon={CheckIcon}
                                    IconTitle="본인 확인"
                                    descriptionTitle="본인확인 역시 쉽습니다."
                                    description={<>ACHER는 로그인, 본인인증 신원 확인 등<br />모든 서비스에서 작동됩니다. 원래 하던 그대로,<br />확인 버튼만 누르면 됩니다.</>}
                                    stepNumber={2}
                                    selected={selectedShowcase === 2}/>
                    </div>
                    <div onMouseEnter={() => setSelectedShowcase(3)}>
                        <AcherStart Icon={RockIcon}
                                    IconTitle="이력 확인"
                                    descriptionTitle="인증이력, 빠른 확인."
                                    description={<>ACHER는 로그인, 본인인증 신원 확인 등<br />모든 서비스에서 작동됩니다. 원래 하던 그대로,<br />확인 버튼만 누르면 됩니다.</>}
                                    stepNumber={3}
                                    selected={selectedShowcase === 3}/>
                    </div>
                </div>
            </div>
        </Page2Wrapper>
    );
};

export default Page2;

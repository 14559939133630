import React from 'react';
import styled from 'styled-components';

import { NormalText } from "../Text";

type ReactSVGElement = React.FC<React.SVGProps<SVGSVGElement>>;

const LogoBox = styled.div`
    margin-bottom: 28px;
`;

const LogoImg = styled.img`
    height: 44px;
`;

type FooterLogoProps = {
    Icon: string;
    Description: JSX.Element;
}

const FooterLogo: React.FC<FooterLogoProps> = ({Icon,  Description}) => {
    return (
        <div>
            <LogoBox>
                <LogoImg src={Icon} />
            </LogoBox>

            <NormalText color="#43455c" fontSize="12px">
                {Description}
            </NormalText>
        </div>
    )
}

export default FooterLogo;

import React, {useState} from "react";
import styled from "styled-components";

import BasePage from "../components/BasePage";
import AcherStart from "../components/Page2/AcherStart";

import { ReactComponent as UserIcon } from "../assets/Page3/user-icon.svg";
import { ReactComponent as RockIcon } from "../assets/Page3/rock-icon.svg";
import { ReactComponent as CheckIcon } from "../assets/Page3/check-icon.svg";

import { ReactComponent as EasyAcher } from "../assets/Page3/easy-acher.svg";

import PhoneImg from "../assets/Page3/phone-img-white.png";

const Page3Wrapper = styled(BasePage)`
    input[type=radio] {
        display: none;
    }
`

const Page3: React.FC = () => {
    const [selectedShowcase, setSelectedShowcase] = useState<number>(1);
    return (
        <Page3Wrapper backgroundColor='#145ef8'>
            <div className="centered-text easy-acher-text">
                <EasyAcher title="암호화폐는 어렵지만 아처는 간편합니다." aria-labelledby="title" />
            </div>
            <div className="acher-start-box">
                <img src={PhoneImg}
                     alt="'나의 지갑' 메인 화면을 보여주고 있는 휴대폰 화면.
                          화면에는 나의 주소 정보와 나의 누적 재산이 적혀있으며, 이더리움, 클레이튼, 비트코인 각 화폐에 보유량이 표시되고 있고,
                          간편하게 사용 할 수 있는 '주소 복사' 버튼과 실시간으로 확인하기 위한 '새로 고침' 버튼이 배치되어 있다." width="455px" height="792px" />
                {/* 휴대폰 이미지 png 크기가 달라서 일단 강제로 맞춰놓았습니다. 나중에 svg 같은 사이즈로 뽑히면 정상적으로 적용 될 것이라고 생각합니다. */}
                <div>
                    <div onMouseEnter={() => setSelectedShowcase(1)}>
                        <AcherStart Icon={UserIcon}
                                    IconTitle="자산 관리"
                                    descriptionTitle="간단하게 본인을 등록하세요."
                                    description={<>ACHER는 로그인, 본인인증 신원 확인 등<br />모든 서비스에서 작동됩니다. 원래 하던 그대로,<br />확인 버튼만 누르면 됩니다.</>}
                                    stepNumber={1}
                                    selected={selectedShowcase === 1}
                                    backgroundColor="#578dff"
                                    textColor="#fff" />
                    </div>

                    <div onMouseEnter={() => setSelectedShowcase(2)}>
                        <AcherStart Icon={CheckIcon}
                                    IconTitle="본인 확인"
                                    descriptionTitle="본인확인 역시 쉽습니다."
                                    description={<>ACHER는 로그인, 본인인증 신원 확인 등<br />모든 서비스에서 작동됩니다. 원래 하던 그대로,<br />확인 버튼만 누르면 됩니다.</>}
                                    stepNumber={2}
                                    selected={selectedShowcase === 2}
                                    backgroundColor="#578dff"
                                    textColor="#fff" />
                    </div>
                    <div onMouseEnter={() => setSelectedShowcase(3)}>
                        <AcherStart Icon={RockIcon}
                                    IconTitle="이력 확인"
                                    descriptionTitle="인증이력, 빠른 확인."
                                    description={<>ACHER는 로그인, 본인인증 신원 확인 등<br />모든 서비스에서 작동됩니다. 원래 하던 그대로,<br />확인 버튼만 누르면 됩니다.</>}
                                    stepNumber={3}
                                    selected={selectedShowcase === 3}
                                    backgroundColor="#578dff"
                                    textColor="#fff" />
                    </div>
                </div>
            </div>
        </Page3Wrapper>
    );
};

export default Page3;

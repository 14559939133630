import React from 'react';
import styled from 'styled-components';

import {Header3, NormalText} from '../Text';
import Colors from "../../Colors";

type ReactSVGElement = React.FC<React.SVGProps<SVGSVGElement>>;

const WhyBoxContainer = styled.section`
    height: 300px;
    width: 348px;
    padding: 1.5em;
    background: white;
    border-radius: 1em;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    transition: box-shadow 0.3s;

    &:not(:last-child) {
        margin-right: 29px;
    }

    &:hover {
        box-shadow: 0 10px 20px 0 rgba(1, 2, 28, 0.16);
    }

    ${Header3} {
        margin: 1em 0 10px;
    }

`;

type WhyBoxProps = {
    Icon: ReactSVGElement;
    title: string;
    description: string;
};

const WhyBox: React.FC<WhyBoxProps> = ({Icon, title, description}) => {
    return (
        <WhyBoxContainer>
            <Icon />
            <Header3>{title}</Header3>

            <NormalText color={Colors.TEXT_BATTLESHIP_GREY}>
                {description}
            </NormalText>
        </WhyBoxContainer>
    )
};

export default WhyBox;

import styled from 'styled-components';

type CenterWrapperProps = {
    baseWidth?: string;
};

const CenterWrapper = styled.div<CenterWrapperProps>`
    width: 100%;
    max-width: ${({baseWidth}) => baseWidth || '1100px'};
    box-sizing: border-box;

    margin: 0 auto;

    @media screen and (max-width: ${({baseWidth}) => baseWidth || '1100px'}) {
    }
`;

export default CenterWrapper;
